/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@font-face {
  font-family: slight;
  src: url('./../fonts/Slight-Regular.ttf');
}

.slight {
  font-family: slight, sans-serif;
}

.text-shadow-navbar {
  text-shadow: 0px 1px 8px white;
}
.marginTop2Bottom2Safari {
  -web-margin-before: 1.5rem;
  -web-margin-after: 1.5rem;
}
.marginTop0Bottom2Safari {
  -web-margin-before: 0;
  -web-margin-after: 1.5rem;
}
.carousel-item {
  // position: relative !important;
  // width: 100% !important;
  // backface-visibility: hidden !important;
  transition: transform 1.1s ease !important;
  transition: -webkit-transform 1.1s ease !important;
  transition: transform 1.1s ease !important;
  transition: transform 1.1s ease, -webkit-transform 1.1s ease;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
.carouselContainerLazy {
  animation: carousellazy 3s linear;
}
.rdt_Table .rdt_TableRow:hover {
  // background-color: #0a3161f1 !important;
  // color: #fff !important;
  cursor: pointer;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.instagram {
  background-color: #833ab4 !important;
}

.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  height: auto !important;
}

.fs-96 {
  font-size: 96px;
}

.fs-50 {
  font-size: 50px;
}

.py-image {
  padding: 0px 0;
  object-fit: contain;
}

.object-fit-contain {
  object-fit: contain;
}

.object-fit-cover {
  object-fit: cover;
}

.hover-btn-danger:hover {
  border-color: #811524 !important;
  background-color: #811524 !important;
  box-shadow: 0 8px 25px -8px #811524;
}

.footer-flex {
  place-self: center;
}

.place-self-center {
  place-self: center;
}

.arrow-circle {
  bottom: 30%;
  z-index: 9;
  // display: flex;
  justify-content: center;
  height: auto;
  width: 100%;

  svg {
    color: #fff;
    height: 100px;
    width: 100px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-30%);
    animation: scroll 3s infinite;

    &:hover {
      cursor: pointer;
    }
  }
}

@keyframes scroll {
  0% {
    // opacity: 1;
    top: 0;
  }
  50% {
    top: 40px;
  }
  100% {
    // opacity: 0;
    top: 0;
  }
}

@keyframes carousellazy {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0.3;
  }
  85% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.pt-6 {
  padding-top: 6rem !important;
}

.pt-3rem {
  padding-top: 5rem;
}

.text-justify {
  text-align: justify;
}

.fs-16 {
  font-size: 16px;
}

.line-height-none {
  line-height: unset !important;
}

.animated {
  transition: all 0.8s ease-in-out;
}

.mb--100 {
  bottom: -100% !important;
}

.w-80 {
  width: 90% !important;
}

@media (max-width: 1713px) {
  .column-action {
    width: 170px;
  }

  .mb-0\.5 {
    margin-bottom: 0.5rem;
  }
}

// xl
@media (min-width: 1200px) {
  .border-lg-end {
    border-right: 1px solid #ebe9f1;
  }

  .fs-1rem {
    font-size: 1rem;
  }

  .fs-1\.25vw {
    font-size: 1.25vw;
  }

  .fs-27 {
    font-size: 27px;
  }

  .fs-20 {
    font-size: 20px;
    line-height: 24px;
  }

  .fs-12 {
    font-size: 12px !important;
  }

  .fs-10 {
    font-size: 10px;
  }

  .footer1.pb-2 {
    margin-bottom: -22px;
  }

  nav,
  nav .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

// lg
@media (min-width: 992px) {
  .fs-72 {
    font-size: 72px;
  }

  .py-image {
    height: 83px;
  }

  #payment_carousel .swiper-wrapper {
    justify-content: center;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .border-lg-end {
    border-right: 1px solid #ebe9f1;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .fs-72 {
    font-size: 60px;
  }

  .fs-1\.5vw {
    font-size: 1.5vw;
  }

  .fs-1\.25vw {
    font-size: 1.25vw;
  }

  .fs-1\.2vw {
    font-size: 1.2vw;
  }

  .fs-1vw {
    font-size: 1vw;
  }

  .fs-27 {
    font-size: 21px;
  }

  .fs-20 {
    font-size: 17px;
  }

  .fs-lg-14 {
    font-size: 14px;
  }

  .fs-12 {
    font-size: 10px !important;
  }

  .fs-10,
  .fs-lg-10 {
    font-size: 10px;
  }

  .fs-md-15 {
    font-size: 15px !important;
  }
}

// md
@media (max-width: 991px) {
  .fs-20 {
    font-size: 15px;
  }

  .mt--1 {
    margin-top: -60px;
  }

  .h-60 {
    height: 60px;
  }
}

@media (min-width: 768px) {
  .w-md-50 {
    width: 50%;
  }

  .fs-72 {
    font-size: 60px;
  }

  .mt-15 {
    margin-top: 15px !important;
  }

  .my-80 {
    margin-left: 80px;
    margin-right: 80px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .fs-md-14 {
    font-size: 14px;
  }

  .text-12,
  .fs-12 {
    font-size: 12px;
  }

  .fs-10 {
    font-size: 10px;
  }

  .fs-1\.5vw {
    font-size: 2vw;
  }

  .fs-1\.25vw {
    font-size: 1.7vw;
  }
}

// sm
@media (max-width: 767px) {
  .w-sm-75 {
    width: 75%;
  }
}

@media (min-width: 576px) {
  .h-sm-100 {
    height: 100%;
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .fs-72 {
    font-size: 50px;
  }

  .h-sm-100 {
    height: 100%;
  }

  .fs-md-14 {
    font-size: 14px;
  }

  .fs-12 {
    font-size: 12px;
  }

  .fs-10 {
    font-size: 10px;
  }
}

@media (min-width: 576px) {
  .border-sm-end {
    border-right: 1px solid #ebe9f1;
  }
}

@media (max-width: 767.98px) {
  .fs-96 {
    font-size: 60px;
  }

  .fs-50 {
    font-size: 37px;
  }

  .fs-72 {
    font-size: 35px;
  }

  .fs-16 {
    font-size: 16px !important;
  }

  [dir='ltr'] .header-navbar.floating-nav {
    margin-left: 0;
    margin-right: 0;
  }

  html[dir] body .app-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
#Spring,
#Winter,
#Fall {
  width: 1.285rem;
  height: 1.285rem;
  margin-right: 10px;
}
#popUpContainer {
  width: 70%;
  margin-left: 30%;
  display: flex;
}
#popUpContent {
  display: flex;
  flex-direction: wrap;
  align-items: center;
  justify-content: start;
  // display: block;
}
#swaltitle {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1em 0.8em 0;
  color: inherit;
  // font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}
#customLabel {
  margin-right: 30px;
}

.scale-1:hover {
  transform: scale(1);
}

.sidenav-overlay.show {
  opacity: 0 !important;
}

.mt-15 {
  margin-top: 0;
}

.login-text {
  z-index: 2;
  font-size: 50px;
  line-height: 80px;
  font-weight: 600;
  right: 50px;
  bottom: 150px;
  color: #004e70;
}

.react-multiple-carousel__arrow {
  z-index: 10 !important;
}